import React from 'react';
import { Box, Typography, createStyles, withStyles } from '@material-ui/core';
import { flow, get, includes, groupBy, sortBy } from 'lodash';

import { withStoreInfo } from 'hooks/useStoreInfo';
import { withAuth } from 'hooks/useAuth';

import DeviceCard from './components/DeviceCard';

const { REACT_APP_SANDBOXVR_TV_BASE_URL } = process.env;

const DEVICE_MODE_RECAP = 'recap';
const DEVICE_MODE_BARRACK = 'barrack';

const styles = createStyles(theme => ({
  subTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  beforeDivider: {
    width: theme.spacing(2),
  },
  divider: {
    flex: 1,
  },
}));
// interface Props extends WithStyles<typeof styles> {
// }

class DeviceRoute extends React.Component {
  handleStopPlayClick = async deviceId => {
    const { auth } = this.props;
    await fetch(`${REACT_APP_SANDBOXVR_TV_BASE_URL}/reset`, {
      method: 'POST',
      body: JSON.stringify({ deviceId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  render() {
    const { classes, storeInfo } = this.props;
    const canStopDevices = storeInfo.devices
      .filter(x =>
        includes(
          [DEVICE_MODE_BARRACK, DEVICE_MODE_RECAP],
          get(x, 'config.deviceMode', '').toLowerCase(),
        ),
      )
      .filter(x => get(x, 'config.active', false))
      .filter(x => !get(x, 'config.developerDevice', false));

    const deviceGroup = groupBy(canStopDevices, x => get(x, 'config.deviceMode', '').toLowerCase());

    return (
      <Box display="flex" flexDirection="column" pb={8}>
        {Object.entries(deviceGroup)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([k, ds]) => (
            <Box key={k} pt={2}>
              <Box textAlign="left" px={2} display="flex" alignItems="center">
                <hr className={classes.beforeDivider} />
                <Typography variant="h6" className={classes.subTitle}>
                  {k.toUpperCase()}
                </Typography>
                <hr className={classes.divider} />
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="center">
                {sortBy(ds, ['config.roomNumber', 'config.displayName', 'config.serial']).map(d => (
                  <DeviceCard
                    key={d.config.serial}
                    id={d.config.serial}
                    title={d.config.displayName}
                    subTitle={d.config.serial}
                    description={
                      d.config.roomNumber &&
                      d.config.deviceMode === DEVICE_MODE_BARRACK &&
                      `Room ${d.config.roomNumber}`
                    }
                    onStopClick={this.handleStopPlayClick}
                  />
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    );
  }
}

export default flow([withStyles(styles), withAuth, withStoreInfo])(DeviceRoute);
