import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { auth, database } from 'firebase/app';

class SimpleStore {
  state = {};
  listener = [];

  constructor(state = {}) {
    this.state = state;
  }

  getState = () => {
    return this.state;
  };
  sub = fn => {
    this.listener.push(fn);
    return () => {
      this.listener = this.listener.filter(x => x !== fn);
    };
  };
  setState = state => {
    this.state = { ...this.state, ...state };
    this.listener.forEach(fn => fn(this.state));
  };
}

const AuthStore = new SimpleStore({
  user: null,
  token: null,
  isInited: false,
  signIn: null,
});
const AuthContext = createContext(AuthStore);
const AuthProvider = ({ children }) => {
  AuthStore.setState({
    isInited: true,
    token: null,
    signIn: (username, password) => {
      return new Promise((resolve, reject) => {
        AuthStore.setState({ isInited: true, token: null });
        const Parse = require('parse');
        Parse.User.logIn(username, password)
          .then(user => {
            if (user) {
              const loggedInUser = {
                user: user,
                // use local expiry date for now. Not checking session in back4app. 7 day for expiry
                expiryDate:
                  new Date().getTime() +
                  (process.env.NODE_ENV === 'development' ? 120 : 60 * 60 * 24 * 7) * 1000,
              };
              window.localStorage.setItem('sessionUser', JSON.stringify(loggedInUser));
              resolve(loggedInUser);
              AuthStore.setState({ user: loggedInUser });
            } else {
              reject('Unexpected Error');
            }
          })
          .catch(error => {
            reject('Error: ' + error.code + ' ' + error.message);
          });
      });
    },
    signOut: () => {
      AuthStore.setState({ user: null, token: null });
    },
  });

  useEffect(() => {
    const currentUser = JSON.parse(window.localStorage.getItem('sessionUser'));
    const currentTime = new Date().getTime();
    if (currentUser && currentUser?.expiryDate && currentUser.expiryDate > currentTime) {
      AuthStore.setState({ user: currentUser, isInited: true });
    }
  }, []);
  return <AuthContext.Provider value={AuthStore}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const [, forceUpdate] = useReducer(s => s + 1, 0);
  const AuthStore = useContext(AuthContext);
  useEffect(() => {
    return AuthStore.sub(() => {
      forceUpdate({});
    });
  });
  return AuthStore.getState();
};

const withAuth = WrappedComponent => {
  const C = props => {
    const auth = useAuth();
    return <WrappedComponent {...props} auth={auth} />;
  };
  C.displayName = `withAuth(${WrappedComponent.displayName || WrappedComponent.name})`;
  return C;
};

export { useAuth as default, AuthProvider as Provider, AuthStore, withAuth };
