import React, { useState, useContext } from 'react';
import LoginButton from './LoginButton';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useAuth from 'hooks/useAuth';

const Login = ({ onLogin = () => {} }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errMsg, setErrMsg] = useState();
  const { signIn } = useAuth();

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setErrMsg(null);
      await performSignIn();
    } catch {
      setErrMsg('Incorrect username and password.');
    }
  };

  const performSignIn = async () => {
    return await signIn(username, password);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <img
        src="https://sandboxvr.com/press/images/logo_horizontal.png"
        style={{ width: '45vw', marginBottom: '30px' }}
        alt="sandboxvr logo"
      />
      {/* <LoginButton /> */}
      <Typography style={{ color: 'red' }}>{errMsg}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          style={{ display: 'block', marginTop: 10 }}
          id="username"
          label="Username"
          value={username ?? ''}
          onChange={e => setUsername(e.target.value)}
        />
        <TextField
          required
          style={{ display: 'block', marginTop: 10 }}
          id="password"
          label="Password"
          type="password"
          value={password ?? ''}
          onChange={e => setPassword(e.target.value)}
        />
        <Button style={{ display: 'block', marginTop: 20 }} type="submit" variant="outlined">
          Login
        </Button>
      </form>
    </Box>
  );
};

export default Login;
