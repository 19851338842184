import React, { Suspense, useCallback } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Box } from '@material-ui/core';

import ErrorBoundary from 'components/ErrorBoundary';
import Workspace from './components/Workspace';
import AuthWrapper from './components/AuthWrapper';
import Panel from './components/Panel';
import { Provider as AuthProvider } from 'hooks/useAuth';

import './App.css';

import Streaming from './containers/StreamingPage';

const history = createBrowserHistory();

const Loading = () => (
  <Box width="100%" textAlign="center">
    <h1>Loading ...</h1>
  </Box>
);

const Layout = props => {
  const { children } = props;
  const renderError = useCallback(err => <h1>{err.message}</h1>, []);
  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary renderError={renderError}>{children}</ErrorBoundary>
    </Suspense>
  );
};

const MainPage = () => {
  return (
    <Workspace>
      <Panel />
    </Workspace>
  );
};

const StreamPage = () => {
  return (
    <Workspace>
      <Streaming />
    </Workspace>
  );
};

const App = () => (
  <AuthProvider>
    <Router history={history}>
      <Switch>
        <Route path="/:location">
          <Layout>
            <AuthWrapper>
              <Switch>
                <Route exact path="/:location" component={MainPage} />
                <Route path="/:location/streaming" component={StreamPage} />
                <Route>
                  <h1>404 Page Not Found</h1>
                </Route>
              </Switch>
            </AuthWrapper>
          </Layout>
        </Route>
        <Route>
          <h1>404 Page Not Found</h1>
        </Route>
      </Switch>
    </Router>
  </AuthProvider>
);

export default App;
