import React from 'react';
import Login from './Login';
import useAuth from 'hooks/useAuth';
import { flow } from 'lodash';

import { withStoreInfo } from 'hooks/useStoreInfo';

const AuthWrapper = ({ children }) => {
  const { user, isInited, signIn } = useAuth();
  if (!isInited) return null;
  return <>{user ? children : <Login onLogin={signIn} />}</>;
};
export default flow([withStoreInfo])(AuthWrapper);
