import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApolloProvider } from 'react-apollo';
import { Box, Fab } from '@material-ui/core';
import { ExitToApp, LiveTv } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { auth } from 'firebase/app';
import { useLocation } from 'react-router-dom';

import apolloClient from 'utils/apolloClient';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Workspace = ({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { signOut } = useAuth();
  const logout = () => {
    signOut();
  };
  return (
    <section>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
        style={{
          position: 'absolute',
          bottom: '1vh',
          right: '1vh',
          zIndex: 1,
        }}
      >
        {!/^\/(\w)+\/streaming(\/)?/.test(pathname) && (
          <Link
            className={classes.link}
            to={location => {
              // prevent append the path at same page
              if (/^\/(\w)+\/streaming(\/)?/.test(location.pathname)) return location;
              return {
                ...location,
                pathname: `${location.pathname}/streaming`,
              };
            }}
          >
            <Fab color="primary" aria-label="live" className={classes.margin}>
              <LiveTv />
            </Fab>
          </Link>
        )}
        <Fab color="secondary" aria-label="add" onClick={logout} className={classes.margin}>
          <ExitToApp />
        </Fab>
      </Box>
      <ApolloProvider client={apolloClient}>
        <div className="App">
          {apolloClient &&
            React.cloneElement(children, {
              client: apolloClient,
            })}
        </div>
      </ApolloProvider>
    </section>
  );
};

export default Workspace;
