import eventStatusCode from '../config/code.json';
import axios from 'axios';
import { get } from 'lodash';

const isQueueStatus = function isQueueStatus(event) {
  // if (event.checkedIn) {
  //   if (event.checkedIn.guests.length > 0 || event.checkedIn.minors.length > 0) {
  //     // check if room has any assignment then will not displayed
  //     if (event.assignedRoom !== null && event.assignedRoom !== eventStatusCode.UNASSIGNED)
  //       return false;
  //     if (event.assignedBarrack === null) return true;
  //     if (event.assignedBarrack === eventStatusCode.UNASSIGNED) return true;
  //   }
  // }
  if (event.assignedRoom !== null && event.assignedRoom !== eventStatusCode.UNASSIGNED)
    return false;
  if (event.assignedBarrack === null) return true;
  if (event.assignedBarrack === eventStatusCode.UNASSIGNED) return true;
  return false;
};

const getEventStatus = function getEventStatus({ assignedBarrack, assignedRoom }) {
  let spaceType = 'Queue';
  let spaceNumber = '';
  if (assignedBarrack === null || assignedBarrack === eventStatusCode.UNASSIGNED) {
    spaceType = 'Queue';
    spaceNumber = '';
  } else if (assignedRoom === null || assignedRoom === eventStatusCode.UNASSIGNED) {
    spaceType = 'Barrack';
    spaceNumber = assignedBarrack;
  } else {
    spaceType = 'Room';
    spaceNumber = assignedRoom;
  }
  return {
    spaceType,
    spaceNumber,
  };
};

/**
 * clearBarrackDevicesByEventId will get the store devices list and clear the device if the event current displayed.
 * it ensure only one event will display at one device.
 *
 * @param {Object} {
 *   location,
 *   eventId,
 *   token,
 * }
 */
const clearBarrackDevicesByEventId = async function clearBarrackDevicesByEventId({
  location,
  eventId,
  token,
}) {
  const SANDBOXVR_BASE_URL = process.env.REACT_APP_SANDBOXVR_TV_BASE_URL
    ? process.env.REACT_APP_SANDBOXVR_TV_BASE_URL
    : 'http://localhost';
  const result = await axios.post(
    `${SANDBOXVR_BASE_URL}/devices`,
    {
      location,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const targetedDevices = result.data
    .filter(device => device.config.deviceMode === 'barrack')
    .filter(device => {
      if (get(device, 'action.showEvent.eventId')) {
        return device.action.showEvent.eventId === eventId;
      }
      return false;
    });
  if (targetedDevices.length === 0) return;
  const resetTasks = targetedDevices.map(async device => {
    await axios.post(
      `${SANDBOXVR_BASE_URL}/reset`,
      {
        deviceId: device.config.serial,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  });
  return Promise.race(resetTasks);
};

export { isQueueStatus, getEventStatus, clearBarrackDevicesByEventId };
